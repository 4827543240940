import React, { useEffect, useState } from 'react';
import { css, cx } from 'emotion';
import { stylesFactory } from '@grafana/ui';

export interface StatusKpiPanelOptions {
  header: string | undefined;
  presseState: string | undefined;
  zylinderState: string | undefined;
  uebergabeState: string | undefined;
  kolbenstangeState: string | undefined;
  width: number;
}

export const StatusKPIItem: React.FC<StatusKpiPanelOptions> = (options: StatusKpiPanelOptions) => {
  const styles = getStyles();
  const [zylinderState, setZylinderState] = useState<string | undefined>(options.zylinderState);
  const [kolbenstangeState, setKolbenstangeState] = useState<string | undefined>(options.kolbenstangeState);
  const [presseState, setPresseState] = useState<string | undefined>(options.presseState);
  const [uebergabeState, setUebergabeState] = useState<string | undefined>(options.uebergabeState);

  useEffect(() => {
    setZylinderState(options.zylinderState);
    setKolbenstangeState(options.kolbenstangeState);
    setPresseState(options.presseState);
    setUebergabeState(options.uebergabeState);
  }, [options]);

  return (
    <div
      className={cx(
        css`
          display: grid;
          height: 160px;
          background: #303030;
          padding: ${options.width < 400 ? '0.5rem' : '1rem'};
        `
      )}
    >
      <p className={styles.header}>{options.header}</p>
      <div
        className={cx(css`
          display: grid;
          grid-template-columns: auto auto;
        `)}
      >
        <div
          className={cx(css`
            display: flex;
          `)}
        >
          <div
            className={cx(
              styles['status-circle'],
              css`
                background-color: ${presseState === 'aktiv' ? '#8bb948' : '#a5a5a5'};
              `
            )}
          ></div>
          <p>
            <strong>Presse:</strong> {presseState}
          </p>
        </div>
        <div
          className={cx(css`
            display: flex;
          `)}
        >
          <div
            className={cx(
              styles['status-circle'],
              css`
                background-color: ${zylinderState === 'aktiv' ? '#8bb948' : '#a5a5a5'};
              `
            )}
          ></div>
          <p>
            <strong>Zylinder:</strong> {zylinderState}
          </p>
        </div>
        <div
          className={cx(css`
            display: flex;
          `)}
        >
          <div
            className={cx(
              styles['status-circle'],
              css`
                background-color: ${uebergabeState === 'aktiv' ? '#8bb948' : '#a5a5a5'};
              `
            )}
          ></div>
          <p>
            <strong>Übergabe:</strong> {uebergabeState}
          </p>
        </div>
        <div
          className={cx(css`
            display: flex;
          `)}
        >
          <div
            className={cx(
              styles['status-circle'],
              css`
                background-color: ${kolbenstangeState === 'aktiv' ? '#8bb948' : '#a5a5a5'};
              `
            )}
          ></div>
          <p>
            <strong>Kolbenstange:</strong> {kolbenstangeState}
          </p>
        </div>
      </div>
    </div>
  );
};

const getStyles = stylesFactory(() => {
  return {
    header: css`
      font-size: 1rem;
      font-weight: 600;
    `,
    column: css`
      width: 30%;
      height: unset;
      margin: 0;
      padding: 0;
    `,
    'status-circle': css`
      height: 20px;
      width: 20px;
      margin-right: 10px;
      border-radius: 50%;
    `,
    'state-good': css`
      color: #8bb948;
    `,
    'state-bad': css`
      color: #c3302a;
      svg {
        transform: rotate(180deg);
      }
    `,
  };
});
