import React from 'react';
import { css, cx } from 'emotion';
import { stylesFactory } from '@grafana/ui';

export interface KpiPanelOptions {
  header: string | undefined;
  value: string | undefined;
  state: 'good' | 'bad' | 'none' | undefined;
  deltaInPercent: string | undefined;
  width: number;
  unit: string;
}
const widthBreakpoint = 1000;

export const KPIItem: React.FC<KpiPanelOptions> = (options: KpiPanelOptions) => {
  const styles = getStyles();

  return (
    <div
      className={cx(
        css`
          display: grid;
          height: 160px;
          background: #303030;
          padding: ${options.width < 400 ? '0.5rem' : '1rem'};
        `
      )}
    >
      <p className={styles.header}>{options.header}</p>
      <div
        className={cx(css`
          display: ${options.width < widthBreakpoint ? 'inline-flex' : 'unset'};
          &:after {
            content: '';
            display: table;
            clear: both;
          }
        `)}
      >
        <div
          className={cx(
            styles.column,
            css`
              width: ${options.width < widthBreakpoint ? '45%' : '60%'};
              float: ${options.width < widthBreakpoint ? 'unset' : 'left'};
            `
          )}
        >
          <h1
            className={cx(css`
              font-size: ${options.width < widthBreakpoint ? '1.5rem' : '2.5rem'};
              font-weight: 600;
            `)}
          >
            {options.value ? options.value + options.unit : '...'}
          </h1>
        </div>
        {options.deltaInPercent && (
          <div
            className={cx(
              styles.column,
              options.state === 'good'
                ? styles['state-good']
                : options.state === 'bad'
                ? styles['state-bad']
                : undefined,
              css`
                float: ${options.width < widthBreakpoint ? 'unset' : 'left'};
              `
            )}
          >
            <svg
              className={cx(css`
                margin-top: ${options.width < widthBreakpoint ? '0px' : '10px'};
                margin-left: 5px;
                height: 25px;
                width: 25px;
              `)}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill={options.state === 'good' ? '#8bb948' : options.state === 'bad' ? '#c3302a' : '#303030'}
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z" />
            </svg>
            <div
              className={cx(
                css`
                  margin-top: -25px;
                  margin-left: 30px;
                `
              )}
            >
              <span>{options.deltaInPercent}%</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const getStyles = stylesFactory(() => {
  return {
    header: css`
      font-size: 1rem;
      font-weight: 600;
    `,
    column: css`
      width: 30%;
      height: unset;
      margin: 0;
      padding: 0;
    `,
    'state-good': css`
      color: #8bb948;
    `,
    'state-bad': css`
      color: #c3302a;
      svg {
        transform: rotate(180deg);
      }
    `,
  };
});
