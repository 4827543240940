import React, { useEffect, useState } from 'react';
import { PanelData, PanelProps } from '@grafana/data';
import { css, cx } from 'emotion';
import { stylesFactory } from '@grafana/ui';
import { getValueFromQuery, getStringValueFromQuery } from 'uef-common';
import { KPIContainer } from './KPIContainer';
import { KpiPanelOptions } from './KPI-Item';
import { ImageWithDots } from './ImageWithDots';
import { PluginOptions } from '../types';
import { StatusKpiPanelOptions } from './Status-KPI-Item';

interface Props extends PanelProps<PluginOptions> {}

const calcChangeInPercentage = (prevValue?: string, newValue?: string): number | undefined => {
  if (!prevValue || !newValue) {
    return undefined;
  }
  const prevVal = Number.parseFloat(prevValue);
  const newVal = Number.parseFloat(newValue);
  if (prevVal && newVal && prevVal !== 0) {
    return ((newVal - prevVal) / prevVal) * 100;
  }
  return undefined;
};

const widthBreakpoint = 800;

const getKpiPanelOptions = (
  data: PanelData,
  columnName: string | undefined,
  currentValue: KpiPanelOptions | undefined,
  width: number,
  unit: string
): KpiPanelOptions | undefined => {
  if (!columnName) {
    return undefined;
  }
  const value = getValueFromQuery(data, columnName);
  const deltaInPercent = calcChangeInPercentage(currentValue?.value, value);
  const kpiOptions: KpiPanelOptions = {
    header: columnName,
    value: value,
    deltaInPercent: deltaInPercent?.toFixed(1),
    state: deltaInPercent ? (deltaInPercent > 0 ? 'good' : 'bad') : 'none',
    width: width,
    unit: unit,
  };
  return kpiOptions;
};

const getStatusKpiPanelOptions = (
  data: PanelData,
  header: string,
  column3presseStateColumnName: string | undefined,
  column3zylinderStateColumnName: string | undefined,
  column3uebergabeStateColumnName: string | undefined,
  column3kolbenstangeStateColumnName: string | undefined,
  width: number
): StatusKpiPanelOptions | undefined => {
  const activeStatePresse = getStringValueFromQuery(data, column3presseStateColumnName, header);
  const activeStateZylinder = getStringValueFromQuery(data, column3zylinderStateColumnName, header);
  const activeStateUebergabe = getStringValueFromQuery(data, column3uebergabeStateColumnName, header);
  const activeStateKolbenstange = getStringValueFromQuery(data, column3kolbenstangeStateColumnName, header);

  const statusKpiOptions: StatusKpiPanelOptions = {
    header: header === '' ? 'Status' : header,
    presseState: activeStatePresse === '' ? 'inaktiv' : activeStatePresse,
    zylinderState: activeStateZylinder === '' ? 'inaktiv' : activeStateZylinder,
    uebergabeState: activeStateUebergabe === '' ? 'inaktiv' : activeStateUebergabe,
    kolbenstangeState: activeStateKolbenstange === '' ? 'inaktiv' : activeStateKolbenstange,
    width: width,
  };
  return statusKpiOptions;
};

export const HeaderComponent: React.FC<Props> = ({ options, data, width, height }) => {
  const styles = getStyles();
  const [currentValue1, setCurrentValue1] = useState<KpiPanelOptions | undefined>();
  const [currentValue2, setCurrentValue2] = useState<KpiPanelOptions | undefined>();
  const [currentValue3, setCurrentValue3] = useState<StatusKpiPanelOptions | undefined>();

  const [kpiPanelOptions1, setKpiPanelOptions1] = useState<KpiPanelOptions>();
  const [kpiPanelOptions2, setKpiPanelOptions2] = useState<KpiPanelOptions>();
  const [statusKpiPanelOptions3, setStatusKpiPanelOptions3] = useState<StatusKpiPanelOptions>();

  useEffect(() => {
    setCurrentValue1((prev) =>
      getKpiPanelOptions(data, options.column1ValueQueryName, prev, width, options.column1ValueUnit)
    );
    setCurrentValue2((prev) =>
      getKpiPanelOptions(data, options.column2ValueQueryName, prev, width, options.column2ValueUnit)
    );
    setCurrentValue3((prev) =>
      getStatusKpiPanelOptions(
        data,
        options.column3ValueQueryName,
        options.column3presseStateColumnName,
        options.column3zylinderStateColumnName,
        options.column3uebergabeStateColumnName,
        options.column3kolbenstangeStateColumnName,
        width
      )
    );
  }, [data, options, width]);

  useEffect(() => {
    if (currentValue1) {
      setKpiPanelOptions1(currentValue1!);
    }
    if (currentValue2) {
      setKpiPanelOptions2(currentValue2!);
    }
    if (currentValue3) {
      setStatusKpiPanelOptions3(currentValue3!);
    }
  }, [currentValue1, currentValue2, currentValue3, width, height, options.cardCount]);

  const isLarge = width > widthBreakpoint;

  const VerticalFiller = () => (
    <div
      className={cx(css`
        display: flex;
        flex: 1;
      `)}
    />
  );

  return (
    <div
      className={cx(
        css`
          width: ${width}px;
          height: ${height}px;
          padding: ${isLarge ? '3rem' : '1em'};
        `
      )}
    >
      {isLarge ? (
        // render large layout
        <div
          className={cx(
            css`
              height: 100%;
            `,
            styles.verticalContainer
          )}
        >
          <VerticalFiller />
          <div className={styles.row}>
            <div
              className={cx(
                css`
                  flex: 3;
                  padding: 0 6rem;
                `
              )}
            >
              <h1>Intelligente Druckluft</h1>
              <ImageWithDots options={options} data={data} height={height} />
            </div>
          </div>
          <VerticalFiller />
          <KPIContainer
            kpiOption1={kpiPanelOptions1}
            kpiOption2={kpiPanelOptions2}
            statusKpiOption3={statusKpiPanelOptions3}
            width={width}
            height={height}
          ></KPIContainer>
        </div>
      ) : (
        // render smartphone layout
        <div className={styles.verticalContainer}>
          <div
            className={cx(
              css`
                width: 100%;
                max-height: 284px;
                justify-content: center;
                display: block;
              `
            )}
          >
            <h1>Intelligente Druckluft</h1>
            <ImageWithDots options={options} data={data} height={height} />
          </div>
          <div
            className={cx(css`
              margin-top: 40px;
            `)}
          >
            <KPIContainer
              kpiOption1={kpiPanelOptions1}
              kpiOption2={kpiPanelOptions2}
              statusKpiOption3={statusKpiPanelOptions3}
              width={width}
              height={height}
            ></KPIContainer>
          </div>
        </div>
      )}
    </div>
  );
};

const getStyles = stylesFactory(() => {
  return {
    header: css`
      font-size: 1rem;
      font-weight: 600;
    `,
    row: css`
      display: flex;
    `,
    textColumn: css`
      flex: 1;
      align-self: center;
    `,
    verticalContainer: css`
      display: flex;
      flex-direction: column;
    `,
    text: css`
      word-break: break-all;
    `,
  };
});
