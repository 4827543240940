import { PanelPlugin } from '@grafana/data';
import { PluginOptions } from './types';
import { HeaderComponent } from './components/HeaderComponent';

const statusDotSettings = {
  defaultValue: 0,
  category: ['Punkte'],
  settings: {
    max: 100,
    min: 0,
    step: 1,
  },
};

export const plugin = new PanelPlugin<PluginOptions>(HeaderComponent).setPanelOptions((builder) => {
  return builder
    .addTextInput({
      path: 'column1ValueQueryName',
      name: 'Wert Spalte 1 (QueryName)',
      description: 'Name der Query',
      defaultValue: 'Effizienz',
      category: ['Kacheln'],
    })
    .addTextInput({
      path: 'column1ValueUnit',
      name: 'Wert Spalte 1 (Einheit)',
      description: 'Maßeinheit',
      defaultValue: '%',
      category: ['Kacheln'],
    })
    .addTextInput({
      path: 'column2ValueQueryName',
      name: 'Wert Spalte 2 (QueryName)',
      description: 'Name der Query',
      defaultValue: 'Druckluftverbrauch',
      category: ['Kacheln'],
    })
    .addTextInput({
      path: 'column2ValueUnit',
      name: 'Wert Spalte 2 (Einheit)',
      description: 'Maßeinheit',
      defaultValue: 'l/min',
      category: ['Kacheln'],
    })
    .addTextInput({
      path: 'column3ValueQueryName',
      name: 'Wert Spalte 3 (QueryName)',
      description: 'Name der Query',
      defaultValue: 'Status',
      category: ['Kacheln'],
    })
    .addTextInput({
      path: 'column3presseStateColumnName',
      name: 'Wert Status Presse',
      description: 'Presse Status ColumName',
      defaultValue: 'PresseStatus',
      category: ['Kacheln'],
    })
    .addTextInput({
      path: 'column3zylinderStateColumnName',
      name: 'Wert Status Zylinder',
      description: 'Zylinder Status ColumName',
      defaultValue: 'ZylinderStatus',
      category: ['Kacheln'],
    })
    .addTextInput({
      path: 'column3uebergabeStateColumnName',
      name: 'Wert Status Übergabe',
      description: 'Übergabe Status ColumName',
      defaultValue: 'UebergabeStatus',
      category: ['Kacheln'],
    })
    .addTextInput({
      path: 'column3kolbenstangeStateColumnName',
      name: 'Wert Status Kolbenstange',
      description: 'Kolbenstange Status ColumName',
      defaultValue: 'KolbenstangeStatus',
      category: ['Kacheln'],
    })
    .addNumberInput({
      path: 'statusDotCount',
      name: 'Anzahl Status-Punkte',
      defaultValue: 0,
      category: ['Punkte'],
      settings: {
        integer: true,
        min: 0,
        max: 5,
        step: 1,
      },
    })
    .addFieldNamePicker({
      path: 'dot1StatusQueryName',
      name: 'Wert Punkt 1 (QueryName)',
      description: 'Name der Query',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 0,
    })
    .addSliderInput({
      path: 'dot1XPosition',
      name: 'X-Position Punkt 1 (Prozent)',
      description: 'X-Position Punkt 1',
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 0,
    })
    .addSliderInput({
      path: 'dot1YPosition',
      name: 'Y-Position Punkt 1 (Prozent)',
      description: 'Y-Position Punkt 1',
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 0,
    })
    .addTextInput({
      path: 'dot1Title',
      name: 'Titel im Popover',
      description: 'Titel im Popover',
      defaultValue: '',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 0,
    })
    .addTextInput({
      path: 'dot1Description',
      name: 'Info-Text im Popover',
      description: 'Text im Popover',
      defaultValue: '',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 0,
    })
    .addFieldNamePicker({
      path: 'dot2StatusQueryName',
      name: 'Wert Punkt 2 (QueryName)',
      description: 'Name der Query',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 1,
    })
    .addSliderInput({
      path: 'dot2XPosition',
      name: 'X-Position Punkt 2 (Prozent)',
      description: 'X-Position Punkt 2',
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 1,
    })
    .addSliderInput({
      path: 'dot2YPosition',
      name: 'Y-Position Punkt 2 (Prozent)',
      description: 'Y-Position Punkt 2',
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 1,
    })
    .addTextInput({
      path: 'dot2Title',
      name: 'Titel im Popover',
      description: 'Titel im Popover',
      defaultValue: '',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 1,
    })
    .addTextInput({
      path: 'dot2Description',
      name: 'Info-Text im Popover',
      description: 'Text im Popover',
      defaultValue: '',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 1,
    })
    .addFieldNamePicker({
      path: 'dot3StatusQueryName',
      name: 'Wert Punkt 3 (QueryName)',
      description: 'Name der Query',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 2,
    })
    .addSliderInput({
      path: 'dot3XPosition',
      name: 'X-Position Punkt 3 (Prozent)',
      description: 'X-Position Punkt 3',
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 2,
      ...statusDotSettings,
    })
    .addSliderInput({
      path: 'dot3YPosition',
      name: 'Y-Position Punkt 3 (Prozent)',
      description: 'Y-Position Punkt 3',
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 2,
    })
    .addTextInput({
      path: 'dot3Title',
      name: 'Titel im Popover',
      description: 'Titel im Popover',
      defaultValue: '',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 2,
    })
    .addTextInput({
      path: 'dot3Description',
      name: 'Info-Text im Popover',
      description: 'Text im Popover',
      defaultValue: '',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 2,
    })
    .addFieldNamePicker({
      path: 'dot4StatusQueryName',
      name: 'Wert Punkt 4 (QueryName)',
      description: 'Name der Query',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 3,
    })
    .addSliderInput({
      path: 'dot4XPosition',
      name: 'X-Position Punkt 4 (Prozent)',
      description: 'X-Position Punkt 4',
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 3,
    })
    .addSliderInput({
      path: 'dot4YPosition',
      name: 'Y-Position Punkt 4 (Prozent)',
      description: 'Y-Position Punkt 4',
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 3,
    })
    .addTextInput({
      path: 'dot4Title',
      name: 'Titel im Popover',
      description: 'Titel im Popover',
      defaultValue: '',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 3,
    })
    .addTextInput({
      path: 'dot4Description',
      name: 'Info-Text im Popover',
      description: 'Text im Popover',
      defaultValue: '',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 3,
    })
    .addFieldNamePicker({
      path: 'dot5StatusQueryName',
      name: 'Wert Punkt 5 (QueryName)',
      description: 'Name der Query',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 4,
    })
    .addSliderInput({
      path: 'dot5XPosition',
      name: 'X-Position Punkt 5 (Prozent)',
      description: 'X-Position Punkt 5',
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 4,
    })
    .addSliderInput({
      path: 'dot5YPosition',
      name: 'Y-Position Punkt 5 (Prozent)',
      description: 'Y-Position Punkt 5',
      ...statusDotSettings,
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 4,
    })
    .addTextInput({
      path: 'dot5Title',
      name: 'Titel im Popover',
      description: 'Titel im Popover',
      defaultValue: '',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 4,
    })
    .addTextInput({
      path: 'dot5Description',
      name: 'Info-Text im Popover',
      description: 'Text im Popover',
      defaultValue: '',
      category: ['Punkte'],
      showIf: (currentOptions, data) => currentOptions.statusDotCount > 4,
    });
});
