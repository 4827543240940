import React from 'react';
import { css, cx } from 'emotion';
import { KPIItem, KpiPanelOptions } from './KPI-Item';
import { StatusKPIItem, StatusKpiPanelOptions } from './Status-KPI-Item';

interface KpiContainerProps {
  kpiOption1?: KpiPanelOptions;
  kpiOption2?: KpiPanelOptions;
  statusKpiOption3?: StatusKpiPanelOptions;
  width: number;
  height: number;
}

export const KPIContainer: React.FC<KpiContainerProps> = ({
  kpiOption1,
  kpiOption2,
  statusKpiOption3,
  width,
}: KpiContainerProps) => {
  return (
    <div
      className={cx(
        css`
          display: flex;
          column-gap: 20px;
          row-gap: 10px;
          flex-direction: ${width > 800 ? 'row' : 'column'};
        `
      )}
    >
      <div
        key="effizienz_kpi"
        className={cx(
          css`
            justify-self: center;
            flex: 1;
          `
        )}
      >
        <KPIItem
          header={kpiOption1?.header}
          state={kpiOption1?.state}
          value={kpiOption1?.value}
          unit={kpiOption1?.unit ?? ''}
          deltaInPercent={kpiOption1?.deltaInPercent}
          width={width}
        ></KPIItem>
      </div>
      <div
        key="druckluftverbrauch_kpi"
        className={cx(
          css`
            justify-self: center;
            flex: 1;
          `
        )}
      >
        <KPIItem
          header={kpiOption2?.header}
          state={kpiOption2?.state}
          value={kpiOption2?.value}
          unit={kpiOption2?.unit ?? ''}
          deltaInPercent={kpiOption2?.deltaInPercent}
          width={width}
        ></KPIItem>
      </div>
      <div
        key="status_kpi"
        className={cx(
          css`
            justify-self: center;
            flex: 2;
          `
        )}
      >
        <StatusKPIItem
          header={statusKpiOption3?.header}
          presseState={statusKpiOption3?.presseState}
          zylinderState={statusKpiOption3?.zylinderState}
          uebergabeState={statusKpiOption3?.uebergabeState}
          kolbenstangeState={statusKpiOption3?.kolbenstangeState}
          width={width}
        ></StatusKPIItem>
      </div>
    </div>
  );
};
